<template>
  <div>
    <loading-gif :loading-name="tariffLoadingName"></loading-gif>
    <div v-if="errorText" class="error-text">{{ errorText }}</div>
    <div class="seance-tariff" v-if="editedTariff">
      <b-row>
        <b-col class="col-border">
          <div class="section-title">Taux de participation</div>
          <div class="section-subtitle">Pour les QF en dessous de chaque seuil</div>
          <b-row>
            <b-col>
              <b-form-group
                label="Fixe"
                label-for="fixed_price"
              >
                <decimal-input
                  :disabled="readOnly"
                  id="fixed_price"
                  v-model="editedTariff.fixedPrice"
                  @change="onChange"
                ></decimal-input>
                <div class="help-text2">
                  Ce prix fixe est ajouté au pourcentage quelque-soit le taux
                </div>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Minimum"
                label-for="min_price"
              >
                <decimal-input
                  :disabled="readOnly"
                  id="max_price"
                  v-model="editedTariff.minPrice"
                  @change="onChange"
                ></decimal-input>
                <div class="help-text2">
                  en €, Prix plancher de la séance <b>hors suppléments</b>
                </div>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Maximum"
                label-for="max_price"
              >
                <decimal-input
                  :disabled="readOnly"
                  id="max_price"
                  v-model="editedTariff.maxPrice"
                  @change="onChange"
                ></decimal-input>
                <div class="help-text2">
                  en €, Prix maximum de la séance <b>hors suppléments</b>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                description="Si décoché les suppléments et réductions sont ignorées pour les séances gratuites"
              >
                <b-form-checkbox v-model="editedTariff.allowDiscountOnFreePrice">
                  Autorise les suppléments et réductions sur les séances gratuites
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b>Fixe</b>
              <div class="help-text2">Seulement pour le taux correspondant</div>
            </b-col>
            <b-col>
              <b>Supplément</b>
              <div class="help-text2">en €, sur le prix de séance</div>
            </b-col>
            <b-col><b>Seuil QF</b></b-col>
            <b-col><b>Taux en %</b></b-col>
          </b-row>
          <div  v-for="rate of this.editedTariff.rates" :key="rate.id" class="grid-row">
            <b-row>
              <b-col>
                <decimal-input
                  :disabled="readOnly"
                  :id="'fixedPrice' + rate.id"
                  v-model="rate.fixedPrice"
                  @change="onChange"
                ></decimal-input>
              </b-col>
              <b-col>
                <decimal-input
                  :disabled="readOnly"
                  :id="'additionalPrice' + rate.id"
                  v-model="rate.additionalPrice"
                  @change="onChange"
                ></decimal-input>
              </b-col>
              <b-col>
                <number-input
                  :disabled="readOnly"
                  :id="'threshold' + rate.id"
                  step="1"
                  v-model="rate.threshold"
                  @change="onChange"
                ></number-input>
              </b-col>
              <b-col>
                <decimal-input
                  :disabled="readOnly"
                  :id="'rate' + rate.id"
                  v-model="rate.rate"
                  precision="3"
                  @change="onChange"
                ></decimal-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="rate-price">
                  {{ calculateRatePrice(rate) }}
                </div>
              </b-col>
              <b-col v-if="seanceType.isHourlyTariff()">
                <div class="rate-price">
                  {{ calculateNhoursRatePrice(rate) }}
                </div>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <b-col class="text-right">
              <a href @click.prevent="addRate()">Ajouter un niveau supplémentaire</a>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <div class="section-title">Grille</div>
          <div class="section-subtitle">
            Pour les QF supérieurs au seuil maximal du taux de participation
          </div>
          <div v-if="isGridVisible()">
            <b-row>
              <b-col cols="1">
                <b>QF</b>
              </b-col>
              <b-col cols="5">
                <b-form-group
                  id="scale-label"
                  label-for="scale"
                >
                  <b-form-select
                    id="scale"
                    class="small-select"
                    v-model="customLevelScale"
                  >
                    <b-form-select-option :value="null">
                      échelle par défaut
                    </b-form-select-option>
                    <b-form-select-option
                      :value="scale"
                      v-for="scale in allScales"
                      :key="scale.id"
                      @change="onChange"
                    >
                      {{ scale.name }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col><b>Prix (en €)</b></b-col>
              <b-col>
                <b>Supplément</b>
                <div class="help-text2">Sur le prix de séance</div>
              </b-col>
            </b-row>
            <div v-for="slice of this.editedTariff.slices" :key="slice.id">
              <b-row class="grid-row" v-if="isSliceVisible(slice)">
                <b-col cols="6">
                  <b-form-input
                    :disabled="readOnly"
                    :id="'slice' + slice.id"
                    :value="getSliceName(slice.index)"
                    readonly
                  ></b-form-input>
                </b-col>
                <b-col>
                  <decimal-input
                    :disabled="readOnly"
                    :id="'price' + slice.id"
                    v-model="slice.price"
                    precision="3"
                    @change="onChange"
                  ></decimal-input>
                </b-col>
                <b-col>
                <decimal-input
                  :disabled="readOnly"
                  :id="'sliceAdditionalPrice' + slice.id"
                  v-model="slice.additionalPrice"
                  @change="onChange"
                ></decimal-input>
              </b-col>
              </b-row>
            </div>
          </div>
          <div v-else>
            <b>Les seuils de taux de participation recouvrent l'ensemble de la grille</b>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { mapActions, mapMutations } from 'vuex'
import DecimalInput from '@/components/Controls/DecimalInput'
import NumberInput from '@/components/Controls/NumberInput'
import LoadingGif from '@/components/Controls/LoadingGif'
import { currency } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import { makeFamilyLevelScale, makeSeanceTariff, makeSeanceTariffRate, makeSeanceTariffSlice } from '@/types/tariffs'
import { BackendApi } from '@/utils/http'
import { createMissingObj } from '@/utils/make'

export default {
  name: 'SeanceTariffEditor',
  mixins: [BackendMixin],
  components: {
    DecimalInput,
    NumberInput,
    LoadingGif,
  },
  props: {
    seanceModel: String,
    seanceId: Number,
    youthHome: Object,
    seanceType: Object,
    duration: Number,
    customScaleId: Number,
    readOnly: Boolean,
  },
  data() {
    return {
      tariffLoadingName: 'tariff',
      editedTariff: null,
      customLevelScale: null,
      allScales: [],
      baseTariff: {},
      errorText: '',
    }
  },
  watch: {
    seanceModel: function() { this.loadTariff() },
    seanceId: function() { this.loadTariff() },
    youthHome: function() { },
    seanceType: function() { },
    familyLevelScale: function() {
      this.editedTariff = this.fillTariff(this.baseTariff)
    },
  },
  computed: {
    familyLevelScale() {
      return this.customLevelScale || this.defaultScale
    },
    defaultScale() {
      let matching = []
      const scaleId = this.youthHome.scale
      if (scaleId) {
        matching = this.allScales.filter(elt => elt.id === scaleId)
      }
      return matching.length ? matching[0] : null
    },
    maxRateThreshold() {
      let thresholds = this.editedTariff.rates.map(elt => +elt.threshold).filter(elt => !isNaN(elt))
      if (thresholds.length) {
        return Math.max(...thresholds)
      } else {
        return 0
      }
    },
  },
  methods: {
    ...mapActions(['addError']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadAllFamilyLevelScales() {
      this.errorText = ''
      let url = '/api/tariffs/family-level-scale/'
      let backendApi = new BackendApi('get', url)
      try {
        let resp = await backendApi.callApi()
        this.allScales = resp.data.map(elt => makeFamilyLevelScale(elt)).filter(
          elt => (elt.schoolYear.id === this.youthHome.schoolYear.id) && (elt.slices.length > 0 && elt.slices[0])
        )
        if (this.customScaleId) {
          let matching = []
          const scaleId = this.customScaleId
          if (scaleId) {
            matching = this.allScales.filter(elt => elt.id === scaleId)
          }
          this.customLevelScale = matching.length ? matching[0] : null
        }
      } catch (err) {
        this.allScales = []
        this.errorText = this.getErrorText(err)
      }
    },
    fillTariff() {
      let tariff = makeSeanceTariff(this.baseTariff)
      let slicesCount = 14
      if (this.familyLevelScale) {
        let zeroIndex = this.familyLevelScale.slices.indexOf(0)
        if (zeroIndex < 0) {
          slicesCount = this.familyLevelScale.slices.length
        } else {
          // On affiche un seul zero
          slicesCount = zeroIndex + 1
        }
      }
      if (slicesCount > tariff.slices.length) {
        tariff.slices = createMissingObj(tariff.slices, slicesCount, makeSeanceTariffSlice, true)
      } else {
        // A slice of slices :-)
        tariff.slices = tariff.slices.slice(0, slicesCount)
      }
      tariff.rates = createMissingObj(tariff.rates, 3, makeSeanceTariffRate)
      return tariff
    },
    async loadTariff() {
      this.errorText = ''
      this.startLoading(this.tariffLoadingName)
      await this.loadAllFamilyLevelScales()
      let url = '/api/youth/tariff/' + this.seanceModel + '/' + this.seanceId + '/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.baseTariff = resp.data['tariff']
        this.editedTariff = this.fillTariff(this.baseTariff)
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
      this.endLoading(this.tariffLoadingName)
    },
    onChange() {
      this.$emit('tariff-changed', { tariff: this.editedTariff, customScale: this.customLevelScale, })
    },
    getSliceName(index) {
      let name = 'Tranche ' + (index + 1)
      if (this.familyLevelScale && (index < this.familyLevelScale.slices.length)) {
        name += ' >= QF ' + this.familyLevelScale.slices[index]
      }
      return name
    },
    addRate() {
      this.editedTariff.rates = this.editedTariff.rates.concat([makeSeanceTariffRate()])
    },
    calculateRatePrice(rateObj) {
      const isHourly = this.seanceType.isHourlyTariff()
      if (rateObj.threshold && rateObj.rate) {
        let value = (+rateObj.fixedPrice) + (+rateObj.threshold) * (+rateObj.rate) / 100
        value += +this.editedTariff.fixedPrice
        if (this.editedTariff.maxPrice && this.editedTariff.maxPrice > 0) {
          value = Math.min(value, +this.editedTariff.maxPrice)
        }
        if (this.editedTariff.minPrice && this.editedTariff.minPrice > 0) {
          value = Math.max(value, +this.editedTariff.minPrice)
        }
        if (!isHourly) {
          value += (+rateObj.additionalPrice)
        }
        let price = currency(value)
        if (price === '---') {
          return 'paramètres invalides'
        } else {
          if (isHourly) {
            return 'Prix horaire hors suppl. au QF ' + rateObj.threshold + ': ' + price
          }
          return 'Prix total au QF ' + rateObj.threshold + ': ' + price
        }
      }
      return ''
    },
    calculateNhoursRatePrice(rateObj) {
      if (rateObj.threshold && rateObj.rate) {
        let value = (+rateObj.fixedPrice) + (+rateObj.threshold) * (+rateObj.rate) / 100
        value += +this.editedTariff.fixedPrice
        if (this.editedTariff.maxPrice && this.editedTariff.maxPrice > 0) {
          value = Math.min(value, +this.editedTariff.maxPrice)
        }
        if (this.editedTariff.minPrice && this.editedTariff.minPrice > 0) {
          value = Math.max(value, +this.editedTariff.minPrice)
        }
        value = value * this.duration + (+rateObj.additionalPrice)
        let price = currency(value)
        if (price === '---') {
          return 'paramètres invalides'
        } else {
          return 'Prix pour ' + this.duration + 'h avec suppl. au QF ' + rateObj.threshold + ': ' + price
        }
      }
      return ''
    },
    isSliceVisible(slice) {
      if (this.familyLevelScale && (slice.index < this.familyLevelScale.slices.length)) {
        let level = this.familyLevelScale.slices[slice.index]
        return level >= this.maxRateThreshold
      }
      return false
    },
    isGridVisible() {
      if (this.familyLevelScale && this.familyLevelScale.slices.length) {
        let level = this.familyLevelScale.slices[0]
        return level >= this.maxRateThreshold
      }
      return true
    },
  },
  mounted() {
    this.loadTariff()
  },
}
</script>
<style lang="less">
.section-title {
  font-weight: bold;
}
.section-subtitle {
  font-size: 12px;
  color: #888;
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: solid 1px #d0d0d0;
}
.grid-row {
  margin-bottom: 4px;
}
.scale-name {
  display: inline-block;
  background: #e0e0e0;
  margin: 4px 5px;
  border-radius: 4px;
  padding: 2px 8px;
}
.rate-price {
  text-align: right;
  color: #888;
  font-size: 12px;
  margin-bottom: 5px;
}
.small-select {
  font-size: 10px;
}
</style>
